import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import SelectInput from '@mui/material/Select/SelectInput';


export default function AddQuestion({ open, setOpen, handleSubmit,subject,chapter,getChapter,Level }) {
    const [data, setData] = React.useState({
       subject:"",
       chapter:"",
       level:'',
       question:"",
       option1:"",
       option2:"",
       option3:"",
       option4:"",
       answer:""
    })
    const [chapterList,setChapterList]=React.useState(chapter)
    
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

const fetchChapter=async(subject)=>{
  let chapterV=await getChapter(subject)
  setChapterList(chapterV)
}

  const handleChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      setData({...data,[name]:value})
      name=="subject"&&fetchChapter(value)
  };

  const handleClose = () => {
    setOpen(false);
    };
    
    const handleForm = (e) => {
e.preventDefault()
        handleSubmit(data)
        setData({
            subject:"",
            chapter:""
        })
        handleClose()
    }

  return (
   
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Add Chapter"}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
                  <form method="POST" onSubmit={handleForm} style={{display:"block",justifyContent:"center",margin:"15px auto"}}>
                         <div>
                         <FormControl sx={{  minWidth: '80%' }}>
        <InputLabel id="demo-simple-select-helper-label">Subject</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          name='subject'
          value={data.subject}
          label="Subject"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {subject&&subject.map((item) => (
            <MenuItem value={item.Subject}>{item.Subject}</MenuItem>
          ))}
        </Select>
        
      </FormControl>

                        <br /><br />
                        <FormControl sx={{  minWidth: '80%' }}>
        <InputLabel id="demo-simple-select-helper-label">Chapter</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          name='chapter'
          value={data.chapter}
          label="Chapter"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {chapterList&&chapterList.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        
      </FormControl><br /><br /> <FormControl sx={{  minWidth: '80%' }}>
        <InputLabel id="demo-simple-select-helper-label">Level</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          name='level'
          value={data.level}
          label="Level"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Level&&Level.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        
      </FormControl><br/> <br />
      <TextField
          id="outlined-multiline-flexible"
          label="Question"
          name='question'
          value={data.question}
          onChange={handleChange}
          multiline
          style={{minWidth:"80%"}}
          maxRows={4}
        /><br/> <br />


<Grid style={{maxWidth:"80%",justifyContent:"space-between"}} container >
          <Grid item> <TextField
          id="outlined-multiline-flexible"
          label="Option 1"
          name='option1'
          value={data.option1}
          onChange={handleChange}
          style={{minWidth:"150px"}}
         
        /></Grid>
         <Grid item><TextField
        id="outlined-multiline-flexible"
        label="Option 2"
        name='option2'
        value={data.option2}
        onChange={handleChange}
        style={{minWidth:"150px"}}
         
      /></Grid></Grid>  <br/> <br />

<Grid style={{maxWidth:"80%",justifyContent:"space-between"}} container >
          <Grid item> <TextField
          id="outlined-multiline-flexible"
          label="Option 3"
          name='option3'
          value={data.option3}
          onChange={handleChange}
          style={{minWidth:"150px"}}
         
        /></Grid>
         <Grid item><TextField
        id="outlined-multiline-flexible"
        label="Option 4"
        name='option4'
        value={data.option4}
        onChange={handleChange}
        style={{minWidth:"150px"}}
         
      /></Grid></Grid>  <br/> <br />
       <TextField
          id="outlined-multiline-flexible"
          label="Answer"
          name='answer'
          value={data.answer}
          onChange={handleChange}
          
          style={{minWidth:"80%"}}
       
        /><br/> <br />
                    <div style={{display:"flex" ,width:"100%",justifyContent:"end"}}>  <Button type="submit" variant='contained'  color="secondary">Submit</Button>
                   
                          {"   "} <Button style={{marginLeft:"5px"}} variant='outlined' color='error' onClick={handleClose} autoFocus>
            Cancel
                          </Button></div>
                      </div>
                  </form>
                  {/* </DialogContentText> */}
        </DialogContent>
       
      </Dialog>
   
  );
}