import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';

export default function EditChapterDetails({ open, setOpen, handleSubmit }) {
    const [Chapter, setChapter] = React.useState({
        chapter: "",
        subject: "",
       
    })
    
    
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      
      setChapter({...Chapter,[name]:value})
  };

  const handleClose = () => {
    setOpen(false);
    };
    
    const handleForm = (e) => {
e.preventDefault()
        handleSubmit(Chapter.chapter,Chapter.subject)
        setChapter({
            chapter: "",
        subject: "",
       
        })
        handleClose()
    }

  return (
   
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Edit User's Details"}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
                  <form method="PUT" onSubmit={handleForm} style={{display:"block",justifyContent:"center",margin:"15px auto"}}>
                         <div><TextField
          required
          id="outlined-required"
          label="Chapter"
          name='chapter'
                          value={Chapter.chapter }
                          onChange={handleChange}
                          /><br /><br />
                         <TextField
          required
          id="outlined-required"
          label="Subject"
          name='subject'
                          value={ Chapter.subject}
                          onChange={handleChange}
                          /><br /><br />
                    <div style={{display:"flex" ,width:"100%",justifyContent:"end"}}>  <Button type="submit" variant='contained'  color="secondary">Submit</Button>
                   
                          {"   "} <Button style={{marginLeft:"5px"}} variant='outlined' color='error' onClick={handleClose} autoFocus>
            Cancel
                          </Button></div>
                      </div>
                  </form>
                  {/* </DialogContentText> */}
        </DialogContent>
       
      </Dialog>
   
  );
}