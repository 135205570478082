import { useContext, useEffect, useLayoutEffect, useState } from "react";

import { useScrollToTop } from "./hooks/use-scroll-to-top.js";

import Router from "./routes/sections";
import ThemeProvider from "./theme";
import { useRouter } from "./routes/hooks";
import axios from "axios";
import { useUserContext }  from "./context/UserContextAPI.jsx";
// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  const { isLoggedIn,verifyUser } = useUserContext()
  const [arrayOfPaths,setArrayOfPaths]=useState(["login","register","404","subscribe","logout"])
  const router=useRouter()
  useEffect(() => {
    !isLoggedIn&&!arrayOfPaths.map((i)=>window.location.pathname.includes(i)).some(element => element === true)?router.push("/login"):verifyUser()
  },[isLoggedIn])

  return (
    <ThemeProvider>
      <Router />
   </ThemeProvider>
  );
}
