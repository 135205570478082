import React,{ Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
// import "./app.css";
import "./tailwind.js"
import App from "./App";
import { UserProvider } from "./context/UserContextAPI.jsx";
import { QuestionPaperProvider } from "./context/QuestionPaperContext";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 <React.StrictMode>
  <HelmetProvider>
    <BrowserRouter> <UserProvider>
      <QuestionPaperProvider>
      <Suspense>
        
          <App />
       
      </Suspense> </QuestionPaperProvider>
   </UserProvider>
    </BrowserRouter>
  </HelmetProvider></React.StrictMode>
 
);
