
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  IconButton,
  Button,
  Card,
  Stack,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../../components/iconify';
import {useQuestionContext} from "../../context/QuestionPaperContext"
import axios from 'axios';
import AddQuestion from './add_question';

const UploadQuestion = () => {
    const {GetQuestionBank,getSubjectList,getChapterList,DeleteQuestion,getChapter}=useQuestionContext()
    const [data, setData] = useState([]); 
    const [subject, setSubject] = useState([]);
    const [chapter, setChapter] = useState([]);
    const [page, setPage] = useState(0);;
 const [openC, setOpenC] = useState(false);
 const [rowsPerPage, setRowsPerPage] = useState(5);
 const [searchTerm, setSearchTerm] = useState("");


const fetchQuestionBank=async()=>{
    let mydata=await GetQuestionBank()
    //console.log("aa",mydata)
    setData(mydata)
  }
  
    useEffect(()=>{
  fetchQuestionBank()
    },[])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };
  
  const handleEdit = (id) => {
    //console.log('Edit item with id:', id);
    // Implement edit logic here
    // setOpenC(true)
  };
  
  const handleDeleteClick = async(id) => {
    // Implement delete logic here
  let returnVal=await DeleteQuestion(id)
  // //console.log(returnVal)
  if(returnVal=="Success"){
  
    // setData(returnVal.newResult);
   fetchQuestionBank()
  }
  
  
  };
  // //console.log(data[0].Chapter&&data[0].Chapter,searchTerm)
  // //console.log( data.map(item =>item.Chapter),searchTerm,data&&data[1].Chapter.includes(searchTerm));
  const filteredData = searchTerm===""?data:data&&data.filter((item) => {
    let filData=item.Level.toLowerCase().includes(searchTerm&&searchTerm.toLowerCase()) ||
    item.Subject.toLowerCase().includes(searchTerm&&searchTerm.toLowerCase())||
    item.Chapter.toLowerCase().includes(searchTerm&&searchTerm.toLowerCase())||
    item.Question.toLowerCase().includes(searchTerm&&searchTerm.toLowerCase())
   
   return filData
  }
  );
  
  
  const handleAddQuestionClick=async() => {
    let subjectList=await getSubjectList()
    setSubject(subjectList)  
    let chapterList=await getChapterList()
    setChapter(chapterList)
  setOpenC(true)
  }
  
  const handleAddQuestion=async(newQuestion)=>{
  //console.log(newQuestion)
    const res = await axios.post(
      "https://glorys.in/test-series-backend/api/questionPaper/addQuestion",
     {
      ...newQuestion
     }
    );
    if (res.data.message == "Success") {
      // setData(res.data.result);
      fetchQuestionBank()
    }
  }

  return (
    <Paper>
    <Stack direction="row" alignItems="center" justifyContent="space-between" mr={5} mt={3} mb={3} ml={5}>
       <Typography variant="h4">Question Bank</Typography>

       <Button variant="contained" color="inherit" onClick={handleAddQuestionClick} startIcon={<Iconify icon="eva:plus-fill" />}>
         New Question
       </Button>
     </Stack>
     <AddQuestion
       open={openC}
       setOpen={setOpenC}
       handleSubmit={handleAddQuestion}
       subject={subject}
      getChapter={getChapter}
      chapter={chapter}
       Level={["Level 1", "Level 2", "Level 3"]}
     />
   <TextField
     label="Search"
     variant="outlined"
     value={searchTerm}
     onChange={handleSearchChange}
     style={{ margin: '16px' }}
     />
   <TableContainer>
     <Table>
       <TableHead>
         <TableRow>
           <TableCell>Sr No</TableCell>
          
           <TableCell>Subject</TableCell>
           <TableCell>Chapter</TableCell>
           <TableCell>Level</TableCell>
           <TableCell>Option 1</TableCell>
           <TableCell>Option 2</TableCell>
           <TableCell>Option 3</TableCell>
           <TableCell>Option 4</TableCell>
           <TableCell>Correct Ans.</TableCell>
           <TableCell>Actions</TableCell>
         </TableRow>
       </TableHead>
       <TableBody>
         //console.log(filteredData)
         {filteredData&&filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
           <TableRow key={row.SrNo}>
             <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
             <TableCell>{row.Chapter}</TableCell>
             <TableCell>{row.Subject}</TableCell>
             <TableCell>{row.Question}</TableCell>
             <TableCell>{row.Opt1}</TableCell>
             <TableCell>{row.Opt2}</TableCell>
             <TableCell>{row.Opt3}</TableCell>
             <TableCell>{row.Opt4}</TableCell>

             <TableCell>{row.CorrectAns}</TableCell>
             <TableCell>
               <IconButton onClick={() => handleEdit(row.SrNo)} color="primary">
                 <EditIcon />
               </IconButton>
               <IconButton onClick={() => handleDeleteClick(row.SrNo)} color="secondary">
                 <DeleteIcon />
               </IconButton>
             </TableCell>
           </TableRow>
         ))}
       </TableBody>
     </Table>
   </TableContainer>
   <TablePagination
     rowsPerPageOptions={[5, 10, 25]}
     component="div"
     count={filteredData&&filteredData.length}
     rowsPerPage={rowsPerPage}
     page={page}
     onPageChange={handleChangePage}
     onRowsPerPageChange={handleChangeRowsPerPage}
   />
 </Paper>
  )
}

export default UploadQuestion