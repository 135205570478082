import SvgColor from "../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const StudentNavConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: icon("ic_analytics"),
  },
 
  {
    title: "Take A Test",
    path: "/MCQ_select",
    icon: icon("ic_question"),
  },
  // {
  //   title: "login",
  //   path: "/login",
  //   icon: icon("ic_lock"),
  // },
  {
    title: "Test Records",
    path: "/Test_Record",
    icon: icon("ic_disabled"),
  },
];

const TeacherNavConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: icon("ic_analytics"),
  },
  
  // {
  //   title: "product",
  //   path: "/products",
  //   icon: icon("ic_cart"),
  // },
  // {
  //   title: "blog",
  //   path: "/blog",
  //   icon: icon("ic_blog"),
  // },
  {
    title: "Upload Question Images",
    path: "/upload_ques_img",
    icon: icon("ic_question"),
  },
  {
    title: "Upload Answer Key",
    path: "/upload_answer_key",
    icon: icon("ic_question"),
  },
 
];

const AdminNavConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: icon("ic_analytics"),
  },
  {
    title: "User",
    path: "/user",
    icon: icon("ic_user"),
  },
  {
    title: "Chapters",
    path: "/chapter",
    icon: icon("ic_user"),
  },{
    title: "Upload Question",
    path: "/upload_question",
    icon: icon("ic_user"),
  },
  // {
  //   title: "product",
  //   path: "/products",
  //   icon: icon("ic_cart"),
  // },
  // {
  //   title: "blog",
  //   path: "/blog",
  //   icon: icon("ic_blog"),
  // },
  {
    title: "Upload Question Images",
    path: "/upload_ques_img",
    icon: icon("ic_question"),
  },
  {
    title: "Upload Answer Key",
    path: "/upload_answer_key",
    icon: icon("ic_question"),
  },
  {
    title: "Take A Test",
    path: "/MCQ_select",
    icon: icon("ic_question"),
  },
  // {
  //   title: "login",
  //   path: "/login",
  //   icon: icon("ic_lock"),
  // },
  {
    title: "Test Records",
    path: "/Test_Record",
    icon: icon("ic_disabled"),
  },
];

const SuperAdminNavConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: icon("ic_analytics"),
  },
  {
    title: "User",
    path: "/user",
    icon: icon("ic_user"),
  },
  // {
  //   title: "product",
  //   path: "/products",
  //   icon: icon("ic_cart"),
  // },
  // {
  //   title: "blog",
  //   path: "/blog",
  //   icon: icon("ic_blog"),
  // },
  {
    title: "Upload Question Images",
    path: "/upload_ques_img",
    icon: icon("ic_question"),
  },
  {
    title: "Upload Answer Key",
    path: "/upload_answer_key",
    icon: icon("ic_question"),
  },
  {
    title: "Take A Test",
    path: "/MCQ_select",
    icon: icon("ic_question"),
  },
  // {
  //   title: "login",
  //   path: "/login",
  //   icon: icon("ic_lock"),
  // },
  {
    title: "Test Records",
    path: "/Test_Record",
    icon: icon("ic_disabled"),
  },
];

export {StudentNavConfig,TeacherNavConfig,AdminNavConfig,SuperAdminNavConfig};
