import React, { useEffect, useState } from "react";

import { useRouter } from "../../routes/hooks";

import Iconify from "../../components/iconify";
import { useTheme } from "@emotion/react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { sub } from "date-fns";
import { useQuestionContext } from "../../context/QuestionPaperContext";

// ----------------------------------------------------------------------

export function UploadQuestionImages() {
  const [subject, setSubject] = useState({ selected: "", options: [] });
  const [chapter, setChapter] = useState({ selected: "", options: [] });

  const [courses, setCourses] = useState({ selected: "", options: [] });


const {UploadQuestions,getCourse,getChapter,getSubject}=useQuestionContext()

  const handleSubject = (event) => {
    setSubject({ ...subject, selected: event.target.value });
  };
  const handleChapter = (event) => {
    setChapter({ ...chapter, selected: event.target.value });
  };
  const handleCourses = (event) => {
    setCourses({ ...courses, selected: event.target.value });
  };

  const fetchCourses = async () => {
    let data=await getCourse()
   setCourses({...courses,options:data});
    //console.log(courses)
}
  const fetchChapters = async () => {
let data=await getChapter(subject.selected)
  setChapter({...chapter,options:data});
}
  const fetchSubjects = async () => {
    let data=await getSubject(courses.selected)
  setSubject({...subject,options:data});
}
  useEffect(() => {
   fetchCourses()
  }, []);
  useEffect(() => {
    fetchChapters()
  }, [subject]);

  useEffect(() => {
    fetchSubjects()
  }, [courses]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleUpload = () => {
    //console.log(selectedFiles.values,[selectedFiles])
    if (
      courses.selected !== "" &&
      subject.selected !== "" &&
      chapter.selected !== "" &&
      selectedFiles.length>0
    ) {
      // let uploadImage = [];
      
      //console.log(selectedFiles)
      // //console.log(uploadImage)
      
      // const formData = {
      //   course: courses.selected,
      //   subject: subject.selected,
      //   chapter: chapter.selected,
      //   images: uploadImage,
      // };
     
      UploadQuestions(selectedFiles,courses,subject,chapter,setSelectedFiles,setChapter,setCourses,setSubject)
    }
  };
  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Upload Question Images</Typography>
      </Stack>

      <Stack
        mb={5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      ></Stack>

      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent=""
        mb={5}
        ml={0}
      >
        <FormControl sx={{ m: 1, minWidth: 170 }} size="medium">
          <InputLabel id="demo-select-small-label">Courses</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={courses.selected}
            label="Courses"
            onChange={handleCourses}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {courses.options &&
              courses.options.map((item, i) => (
                <MenuItem value={item} key={i}>
                  {item}
                </MenuItem>
              ))}
            //console.log(courses.options)
          </Select>
        </FormControl>
        {courses.selected&&courses.selected !== "" && (
          <FormControl sx={{ m: 1, minWidth: 170 }} size="medium">
            <InputLabel id="demo-select-small-label">Subject</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={subject.selected}
              label="Subject"
              onChange={handleSubject}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {subject.options&&subject.options.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {courses.selected !== "" && subject.selected !== ""
          && (
          <FormControl sx={{ m: 1, minWidth: 170 }}>
            <InputLabel id="demo-select-small-label">Chapter</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={chapter.selected}
              label="Chapter"
              onChange={handleChapter}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {chapter.options&&chapter.options.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <input type="file" multiple onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
      </Grid>
    </Container>
  );
}
