import { Helmet } from 'react-helmet-async';

// import { ChapterListView } from '../sections/ChapterList/view';
import {UploadChapter}  from '../sections/upload_chapter/index';

// ----------------------------------------------------------------------

export default function UploadChapterPage() {
  return (
    <>
      <Helmet>
        <title> Upload Chapter | Minimal UI </title>
      </Helmet>

      {/* <ChapterListView /> */}
      <UploadChapter />
    </>
  );
}
