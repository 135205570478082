import { Helmet } from 'react-helmet-async';
import {UploadQuestion} from '../sections/upload_question/index';

// import { ChapterListView } from '../sections/ChapterList/view';

// ----------------------------------------------------------------------

export default function UploadQuestionPage() {
  return (
    <>
      <Helmet>
        <title> Upload Question | Minimal UI </title>
      </Helmet>

      {/* <ChapterListView /> */}
      <UploadQuestion />
    </>
  );
}
