import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

import Nav from "./nav";
import Main from "./main";
import Header from "./header";
import axios from "axios";
import UserContext from "../../context/UserContextAPI";
import { useRouter } from "../../routes/hooks";

import { useUserContext }  from "../../context/UserContextAPI";
// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);

  // const router = useRouter();
  // const {userData,setUserData}=useContext(UserContext)
  // useEffect(()=>{
  // axios.get("https://localhost:5090/verify_user")
  // .then(res=>{
  //   if(res.data.Message=="Success"){
  //     setUserData(res.data.user)
  //   }else{
  //     router.push("/login")
  //   }
  // }).then(err=>//console.log(err))
  // },[])

  const { userData } = useUserContext();

  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
