import React from "react";
import { Helmet } from "react-helmet-async";
import { UploadQuestionImages } from "../sections/questionBank/uploadQuestionImages-view";

const UploadQuestionImgPage = () => {
  return (
    <>
      <Helmet>
        <title> Products | Innovative Online Test Series </title>
      </Helmet>

      <UploadQuestionImages />
    </>
  );
};

export default UploadQuestionImgPage;
